import React, { useState } from 'react';
import { FilterList } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import { useTranslation } from '@util/hooks';
import { Button, DatePicker, SearchableSelect, Select, TextInput } from '@components';
import FilterGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import SearchGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';

const CustomSearchGroup = ({ initialFilter, options, onSubmit, onRefreshClick }) => {
    const t = useTranslation('OutpatientStorage');

    const [filterDraft, setFilterDraft] = useState(initialFilter);

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(filterDraft);
    };

    const handleRefreshClick = () => {
        setFilterDraft(initialFilter);
        onRefreshClick();
    };

    return (
        <FilterSearchGroup className="p-0">
            <FilterGroup label={t('Filter', 'Filter')} labelIconName={''}>
                <FilterList>
                    <SearchableSelect
                        title={t('Reason for Outstanding Amount')}
                        data={options.filterOption}
                        selected={filterDraft.filterType}
                        onChange={selected =>
                            setFilterDraft({ ...filterDraft, filterType: selected.map(v => v.value) })
                        }
                        className="mr-2"
                    />
                </FilterList>
            </FilterGroup>
            <SearchGroup label={t('Search', 'Search')} labelIconName={''}>
                <Select
                    options={options.searchOption}
                    value={options.searchOption.find(ele => ele.value === filterDraft.searchType.value)}
                    onChange={selected => {
                        setFilterDraft({ ...filterDraft, searchType: selected });
                    }}
                    className="mr-2"
                />
                <DatePicker
                    value={filterDraft.startDate}
                    handleChange={selected => {
                        setFilterDraft({ ...filterDraft, startDate: selected });
                        onSubmit({ ...filterDraft, startDate: selected });
                    }}
                    withoutTime
                    maxDate={filterDraft.endDate * 1000}
                    disabledKeyboardNavigation
                />
                -
                <DatePicker
                    value={filterDraft.endDate}
                    handleChange={selected => {
                        setFilterDraft({ ...filterDraft, endDate: selected });
                        onSubmit({ ...filterDraft, endDate: selected });
                    }}
                    withoutTime
                    minDate={filterDraft.startDate * 1000}
                    disabledKeyboardNavigation
                />
                <form onSubmit={handleSubmit}>
                    <div className="d-flex">
                        <TextInput
                            value={filterDraft.keyword}
                            placeholder={t('Enter the patients name or patients ID.')}
                            handleChange={e => setFilterDraft({ ...filterDraft, keyword: e.target.value })}
                        />
                        <Button type="submit" className="btn-secondary btn-icon-only" iconName={'search'} />
                        <Button
                            className="btn-line btn-icon-only ml-2"
                            iconName="refresh"
                            onClick={handleRefreshClick}
                        />
                    </div>
                </form>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default CustomSearchGroup;
