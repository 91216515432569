import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Modal, Table } from '@components';
import { useTranslation } from '@util/hooks';
import { Data, Row } from '..';
import useModal from '../../../util/useModal';
import { NullableUnixTimestamp } from '@util/type/util';
import moment from 'moment';
import { formatDateByColumn } from '../../../util/format';
import { DATE_FORMAT } from '@util/times';

interface Props {
    centerName: string;
    data: Data;
    onPageChange: (page: number) => void;
}

const HistoryList = ({ centerName, data, onPageChange }: Props) => {
    const t = useTranslation('OutpatientStorage');

    const [modalContent, setModalContent] = useState({
        text: '',
        url: '',
    });
    const [isOpen, handleToggle, handleOpen] = useModal();

    const columns = useMemo(
        () => [
            {
                Header: t('Reason for Outstanding Amount'),
                accessor: 'unpaidType',
            },
            {
                Header: t('Patient Id'),
                accessor: 'patientId',
            },
            {
                Header: t('Patient name'),
                accessor: 'patientName',
                width: 100,
            },
            {
                Header: t('Dept'),
                accessor: 'departmentName',
                width: 100,
            },
            {
                Header: t('Date of received'),
                accessor: 'appointmentDate',
                Cell: ({ value }: { value: NullableUnixTimestamp }) => (
                    <span>{value ? moment.unix(value).format(DATE_FORMAT.YMD) : '-'}</span>
                ),
            },
            {
                Header: t('Date of occurrence'),
                accessor: 'careEndDate',
                Cell: ({ value }: { value: NullableUnixTimestamp }) => (
                    <span>{value ? moment.unix(value).format(DATE_FORMAT.YMD) : '-'}</span>
                ),
            },
            {
                Header: t('Outstanding Amount'),
                accessor: 'amount',
                Cell: ({ value }: { value: string }) => {
                    return <span>{Number(value).toLocaleString()}</span>;
                },
                width: 120,
            },
            {
                Header: t('Result'),
                width: 70,
                accessor: 'resultType',
                Cell: ({ value }: { value: Row['resultType'] }) => {
                    return value || '-';
                },
            },
            {
                Header: t('Sent date'),
                accessor: 'sendingDate',
                Cell: ({ value, row }: { row: Row; value: NullableUnixTimestamp }) => {
                    return <span>{value ? formatDateByColumn(row, 'sendingDate') : '-'}</span>;
                },
            },
            {
                Header: t('Payment Bank'),
                width: 70,
                accessor: 'paymentBank',
                Cell: ({ value }: { value: string }) => {
                    return value || '-';
                },
            },
            {
                Header: t('Card Authorization Number'),
                accessor: 'cardApproveNum',
                Cell: ({ value }: { value: string }) => {
                    return value || '-';
                },
            },
        ],
        [handleOpen, t],
    );

    return (
        <>
            <TableWrapper>
                <Table columns={columns} data={{ ...data, pageSize: 15 }} onPageChange={onPageChange} />
            </TableWrapper>
            <Modal
                initModal={isOpen}
                toggleModal={handleToggle}
                headerTitle={t('Notification text')}
                bodyText={
                    <ModalBodyWrapper>
                        [{t(centerName)} {t('medical center information')}] {modalContent.text}
                        <div>
                            <a href={modalContent.url} target="_blank" rel="noopener noreferrer">
                                {t('Payment')}
                            </a>
                        </div>
                    </ModalBodyWrapper>
                }
                removeCancel={true}
            ></Modal>
        </>
    );
};

const TableWrapper = styled.div`
    height: 600px;
`;

const ModalBodyWrapper = styled.div`
    white-space: pre;
`;

export default HistoryList;
