import React, { useRef } from 'react';
import { getOutpatientUsageDept, postOutpatientUsageDeptExcel } from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import WidgetCard from '../../Components/WidgetCard';
import useDateTypeOptions from '../util/useDateTypeOptions';
import Chart from './Chart';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useRangeDateSO from '../util/useRangeDateSO';
import SearchGroupSO from '../Components/SearchGroupSO';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import { Trans } from 'react-i18next';
import moment from 'moment';
import useDepartmentOptions from '../util/useDepartmentOptions';
import { LoadingBlock } from '@components';

const getColumnMetas = (excelDownType, t) => {
    const columns = [
        { key: 'regDay', name: t(`Date(${excelDownType})`) },
        { key: 'departmentName', name: t('Department name') },
        { key: 'departmentCode', name: t('Department code') },
        { key: 'todayPatientCount', name: `${t('Same-day Registration')} (${t('Cases')})` },
        { key: 'appointmentPatientCount', name: `${t('Appointment Registration')} (${t('Cases')})` },
        { key: 'patientCount', name: `${t('Total Registration')} (${t('Cases')})` },
        { key: 'todayPatientKioskCount', name: `${t('Using a kiosk after same-day check-in')} (${t('Cases')})` },
        {
            key: 'appointmentPatientKioskCount',
            name: `${t('Using a kiosk after appointment registration')} (${t('Cases')})`,
        },
    ];

    if (excelDownType === 'day') {
        columns.push({ key: 'kioskRate', name: `${t('Kiosk Usage Rate')} (%)` });
    }

    return columns;
};

const getFileName = (startDate, endDate) =>
    `so_kioskData_${moment.unix(startDate).format('YYYYMMDD')}_${moment.unix(endDate).format('YYYYMMDD')}`;

// 외래 사용량 분석(진료과별)
const OutpatientUsageAnalysisByDepartment = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const dateTypeValues = useDateTypeOptions();
    const dateValues = useRangeDateSO(dateTypeValues.dateType);
    const departmentValues = useDepartmentOptions();

    const { state, promise: getData } = useAsync({
        promise: getOutpatientUsageDept,
        immediate: true,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const {
        departmentName1,
        departmentName2,
        kioskRate1,
        kioskRate2,
        totalCount,
        totalKioskCount,
        totalKioskRate,
        kioskCount,
    } = state.response ? state.response.additionalDatas : {};

    const triggerDayDownload = useAsyncExcel({
        promise: postOutpatientUsageDeptExcel,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            excelDownType: 'day',
            columnMetas: getColumnMetas('day', t),
            fileName: getFileName(dateValues.startDate, dateValues.endDate),
            zipFileName: 'so_usage_by_department_day',
        },
        reject: err => console.error(err),
    });
    const triggerMonthDownload = useAsyncExcel({
        promise: postOutpatientUsageDeptExcel,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            excelDownType: 'month',
            columnMetas: getColumnMetas('month', t),
            fileName: getFileName(dateValues.startDate, dateValues.endDate),
            zipFileName: 'so_usage_by_department_month',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        if (!isValidData(data)) {
            return;
        }

        triggerMonthDownload().then(() => {
            triggerDayDownload();
        });
    };

    // kioskRate의 값이 전부 동일한지여부
    const sameKioskCount = data.length > 0 && data.every(item => item.kioskCount === data[0].kioskCount);

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                showDateType
                showDepartment
                dateTypeValues={dateTypeValues}
                departmentValues={departmentValues}
                dateValues={dateValues}
                getData={getData}
                showDownload={isValidData(data)}
                handleDownload={handleDownloadClick}
            />
            <LoadingBlock blocking={state.isLoading}>
                {isValidData(data) ? (
                    <>
                        <InsightBox>
                            <BoxBodyText>
                                <Trans
                                    t={t}
                                    values={{
                                        departmentName1,
                                        departmentName2,
                                        kioskRate1,
                                        kioskRate2,
                                        totalCount,
                                        kioskCount,
                                        totalKioskCount,
                                        totalKioskRate,
                                    }}
                                    components={[<HighlightedText />, <br />]}
                                >
                                    {data.length >= 2
                                        ? sameKioskCount
                                            ? 'During the inquiry period, the total number of applications for the selected departments was <0>{{totalCount}}</0>, with <0>{{totalKioskCount}}</0> processed through kiosks, resulting in a kiosk usage rate of <0>{{totalKioskRate}}</0>%.'
                                            : `During the inquiry period, the total number of applications for the selected departments was <0>{{totalCount}}</0>, with <0>{{totalKioskCount}}</0> processed through kiosks, resulting in a kiosk usage rate of <0>{{totalKioskRate}}</0>%.
<1 /> The department with the highest kiosk usage rate during this period was <0>{{departmentName1}}</0>, with a usage rate of <0>{{kioskRate1}}</0>%.
<1 /> The department with the lowest kiosk usage rate was <0>{{departmentName2}}</0>, with a usage rate of <0>{{kioskRate2}}</0>%.`
                                        : `During the inquiry period, the total number of applications for <0>{{departmentName1}}</0> was <0>{{totalCount}}</0>, with <0>{{kioskCount}}</0> of them processed through kiosks, resulting in a kiosk usage rate of <0>{{kioskRate1}}</0>%`}
                                </Trans>
                            </BoxBodyText>
                        </InsightBox>
                        <Chart data={data} />
                    </>
                ) : (
                    <NoDataBlock text={t('No data')} />
                )}
            </LoadingBlock>
            {children}
        </WidgetCard>
    );
};

export default OutpatientUsageAnalysisByDepartment;
