import React, { useRef, useState } from 'react';
import { OptionType } from '@components/type';
import { useTranslation } from '@util/hooks';
import { WidgetProps } from '../../staticInfo';
import OutstandingAmountList from './Components/OutstandingAmountList';
import SearchGroup from './Components/CustomSearchGroup';
import { useAmountList } from './hooks/useAmountList';
import WidgetCard from '../../Components/WidgetCard';
import moment from 'moment';
import ContextProvider from '@components/ContextProvider';
import outstandingAmountSlice, { OutstandingAmountDispatchContext, OutstandingAmountStateContext } from './slice';
import { UnixTimestamp } from '@util/type/util';
import { getSelectOptions } from './util';

export interface Filter {
    sortOption: OptionType;
    searchType: OptionType;
    startDate: UnixTimestamp;
    endDate: UnixTimestamp;
    keyword: string;
    page: number;
}

const DEFAULT_DATA = {
    page: 0,
    totalCount: 0,
    totalPage: 0,
    pageSize: 0,
    rows: [],
};

const OutstandingAmountNotification = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    return (
        <ContextProvider
            StateContext={OutstandingAmountStateContext}
            DispatchContext={OutstandingAmountDispatchContext}
            slice={outstandingAmountSlice}
        >
            <ContextConsumer widgetInfo={widgetInfo} {...restProps}>
                {children}
            </ContextConsumer>
        </ContextProvider>
    );
};

const ContextConsumer = ({ children, widgetInfo, ...restProps }: WidgetProps) => {
    const widgetRef = useRef(null);
    const t = useTranslation('OutpatientStorage');
    const options = getSelectOptions(t);

    const initialFilter = {
        // 각 옵션 필터별 초기 값 설정
        sortOption: options.orderOptions[3],
        searchType: options.searchOptions[0],
        startDate: moment().subtract(1, 'weeks').startOf('day').unix(),
        endDate: moment().endOf('day').unix(),
        keyword: '',
        page: 1,
    };

    const [filter, setFilter] = useState<Filter>(initialFilter);
    const { data, getList } = useAmountList(filter);

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <div className="d-flex flex-column gap-3">
                <SearchGroup
                    initialFilter={initialFilter}
                    options={options}
                    onSubmit={(filter: Filter) => {
                        setFilter(filter);
                    }}
                    onRefreshClick={() => {
                        setFilter(initialFilter);
                    }}
                    onRefreshList={getList}
                />
                <OutstandingAmountList
                    data={data ?? DEFAULT_DATA}
                    onPageChange={(page: number) => {
                        setFilter({ ...filter, page });
                    }}
                    refreshList={getList}
                />
            </div>
            {children}
        </WidgetCard>
    );
};

export default OutstandingAmountNotification;
