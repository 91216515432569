import { useDispatch } from 'react-redux';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT } from '@reducer/SocketInfo';
import { RealtimeEmergencySensorItemStatusData } from '@util/socket/socketData';
import { deleteEmergencyAlertContents, setEmergencyAlertContents } from '@reducer/EmergencyAlert';
import { useMemo } from 'react';
import { useAppSelector } from '@hooks';

const useEmergencyAlert = () => {
    const dispatch = useDispatch();
    const { groupNums } = useAppSelector(state => state.UserInfo.userInfo) as { groupNums: number[] };
    const socketFilter = useMemo(() => {
        if (groupNums && groupNums.length) {
            return { target: { properties: { ward: { $in: groupNums.map(groupNum => String(groupNum)) } } } };
        }
        return {};
    }, [groupNums]);

    useSocketEvent({
        name: EVENT_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT,
        filterConfig: socketFilter,
        handler: data => {
            const {
                event: { alertOpen },
            } = data as RealtimeEmergencySensorItemStatusData;
            if (alertOpen === 'Y') {
                dispatch(setEmergencyAlertContents(data));
            } else {
                dispatch(deleteEmergencyAlertContents(data));
            }
        },
    });
};

export default useEmergencyAlert;
