import { Row } from './hooks/useAmountList';
import { isToday } from '../util';

export const getSelectOptions = (t: (text: string) => string) => {
    const searchOption = [
        { label: t('Date of Receivables Occurrence'), value: 'careEndDate' },
        { label: t('Notification Talk Sending Date'), value: 'sendingDate' },
    ];

    const orderOption = [
        { label: t('Latest send date'), value: 'latest' },
        { label: t('Earliest send date'), value: 'earliest' },
        { label: t('Newest Receivables First'), value: 'latestOverdue' },
        { label: t('Oldest Receivables First'), value: 'earliestOverdue' },
        { label: t('Total amount desc.'), value: 'desc' },
        { label: t('Total amount asc.'), value: 'asc' },
    ];

    return {
        orderOptions: orderOption,
        searchOptions: searchOption,
    };
};

export const getPreventEventClassName = (original: Row) => {
    const { latestStatus, lastSendingDate } = original;
    const checkedToday = lastSendingDate ? isToday(lastSendingDate) : false;

    const isDisabled = ['발송중'].includes(latestStatus) || checkedToday;

    // 최근발송일시는 성공했을때만 업데이트 됨
    // 상태가 발송중 또는 최근발송일시가 오늘일 경우 -> disabled 쿨래스 적용
    return isDisabled ? 'opacity-5 disabled' : '';
};

// 발송결과가 발송중 or 발송결과가 성공이면서 날짜가 당일이면 false 반환
// 위의 결과를 제외한 나머지 값을 담아야 하기 때문
export const checkNotDisabledData = (original: Row) => {
    const { latestStatus, lastSendingDate } = original;
    const checkedToday = lastSendingDate ? isToday(lastSendingDate) : false;

    return !(latestStatus === '발송중' || (checkedToday && latestStatus === '성공'));
};
