import React, { ChangeEvent, useMemo, useState } from 'react';
import { ConfirmModal, Table } from '@components';
import { useTranslation } from '@util/hooks';
import styled from 'styled-components';
import { Data, Row } from '../hooks/useAmountList';
import useModal from '../../../util/useModal';
import { formatDateByColumn, formatPhoneNumber } from '../../../util/format';
import OutstandingHistory from './OutstandingHistory';
import { setCheckedList, useOutstandingAmountDispatchContext, useOutstandingAmountStateContext } from '../slice';
import moment from 'moment';
import { DATE_FORMAT } from '@util/times';
import { checkNotDisabledData, getPreventEventClassName } from '../util';
import { useConfirm } from '@hooks';

interface Props {
    data: Data;
    onPageChange: (page: number) => void;
    refreshList: () => void;
}

const OutstandingAmountList = ({ data, onPageChange, refreshList }: Props) => {
    const t = useTranslation('OutpatientStorage');
    const [selectedRow, setSelectedRow] = useState<Row | null>(null);
    const [isOpen, handleToggle, handleOpen] = useModal(); // 알림톡 이력 상세 모달
    const { checkedList } = useOutstandingAmountStateContext();
    const dispatch = useOutstandingAmountDispatchContext()!;

    const alertModalProps = useConfirm({
        initModal: false,
        confirmText: t('The payment reminder has already been sent today. Please try again tomorrow.'),
        removeCancel: true,
    });

    const checkData = () => {
        const filteredData = data.rows.filter(checkNotDisabledData);

        // every는 빈 배열일때 true를 반환하기 때문에 filterdData의 length로 한번 더 검증
        return !!(
            filteredData.every(({ unpaidAmountNum }: { unpaidAmountNum: number }) =>
                checkedList.includes(unpaidAmountNum),
            ) && filteredData.length
        );
    };

    // 전체 선택 체크 여부: 모든 개별 체크박스가 체크되었는지 확인
    const isAllChecked = data.rows.length > 0 && checkData();

    // 전체 선택/해제 로직
    const handleAllCheck = (e: ChangeEvent<HTMLInputElement>) => {
        if (isAllChecked && !e.target.checked) {
            // 모든 체크박스를 해제
            dispatch(setCheckedList([]));
        } else {
            // 모든 체크박스를 선택
            const newCheckedList = data.rows
                .filter(checkNotDisabledData)
                .map(({ unpaidAmountNum }: { unpaidAmountNum: number }) => unpaidAmountNum);
            dispatch(setCheckedList(newCheckedList));
        }
    };

    // 개별 선택/해제 로직
    const handleCheck = (unpaidAmountNum: number) => {
        dispatch(
            setCheckedList(
                checkedList.includes(unpaidAmountNum)
                    ? checkedList.filter((originUnpaidAmountNum: number) => originUnpaidAmountNum !== unpaidAmountNum) // 선택 해제
                    : [...checkedList, unpaidAmountNum], // 선택 추가
            ),
        );
    };

    const columns = useMemo(
        () => [
            {
                Header: () => {
                    return <input type="checkbox" className="m-0" checked={isAllChecked} onChange={handleAllCheck} />;
                },
                width: 40,
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    const { patientId, unpaidAmountNum } = original;
                    return (
                        <div
                            className={getPreventEventClassName(original)}
                            onClick={() => {
                                // 성공 + 오늘 날짜 or 발송중
                                if (!checkNotDisabledData(original)) {
                                    alertModalProps.toggleModal();
                                }
                            }}
                        >
                            <input
                                name={patientId}
                                type="checkbox"
                                className="m-0"
                                checked={checkedList.includes(unpaidAmountNum)}
                                onChange={() => handleCheck(unpaidAmountNum)}
                            />
                        </div>
                    );
                },
                accessor: 'select',
            },
            {
                Header: t('Patient number'),
                accessor: 'patientId',
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    return <span className={getPreventEventClassName(original)}>{original.patientId}</span>;
                },
            },
            {
                Header: t('Patient name'),
                accessor: 'patientName',
                width: 100,
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    return <span className={getPreventEventClassName(original)}>{original.patientName}</span>;
                },
            },
            {
                Header: t('Phone Number'),
                accessor: 'phoneNum',
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    return (
                        <span className={getPreventEventClassName(original)}>
                            {formatPhoneNumber(original.phoneNum) || '-'}
                        </span>
                    );
                },
            },
            {
                Header: t('Reason for Outstanding Amount'),
                accessor: 'unpaidType',
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    return <span className={getPreventEventClassName(original)}>{original.unpaidType}</span>;
                },
            },
            {
                Header: t('Dept'),
                accessor: 'departmentCode',
                width: 70,
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    return <span className={getPreventEventClassName(original)}>{original.departmentCode}</span>;
                },
            },
            {
                Header: t('Outstanding Amount (KRW)'),
                accessor: 'amount',
                width: 120,
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    return (
                        <span className={getPreventEventClassName(original)}>
                            {Number(original.amount).toLocaleString()}
                        </span>
                    );
                },
            },
            {
                Header: t('Date of occurrence'),
                accessor: 'careEndDate',
                width: 100,
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    return (
                        <span className={getPreventEventClassName(original)}>
                            {original.careEndDate ? moment.unix(original.careEndDate).format(DATE_FORMAT.YMD) : '-'}
                        </span>
                    );
                },
            },
            {
                Header: t('Date of received'),
                accessor: 'appointmentDate',
                width: 100,
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    return (
                        <span className={getPreventEventClassName(original)}>
                            {original.appointmentDate
                                ? moment.unix(original.appointmentDate).format(DATE_FORMAT.YMD)
                                : '-'}
                        </span>
                    );
                },
            },
            {
                Header: t('Total outstanding amount'),
                accessor: 'totalAmount',
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    return (
                        <span className={getPreventEventClassName(original)}>
                            {Number(original.totalAmount).toLocaleString()}
                        </span>
                    );
                },
            },
            {
                Header: t('Successful Sending History'),
                accessor: 'historyCount',
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    const { historyCount } = original;
                    return (
                        <span
                            className={'cursor-pointer color-primary text-decoration-underline'}
                            onClick={() => {
                                setSelectedRow(original);
                                handleOpen();
                            }}
                        >
                            {historyCount ?? 0}
                            {t(' times')}
                        </span>
                    );
                },
            },
            {
                Header: t('Recent Sending Results'),
                accessor: 'latestStatus',
                Cell: ({ row }: { row: { original: Row } }) => {
                    const { latestStatus } = row.original;
                    return <span className={getPreventEventClassName(row.original)}>{latestStatus || '-'}</span>;
                },
            },
            {
                Header: t('Recent Sending Date and Time'),
                Cell: ({ row }: { row: { original: Row } }) => {
                    const { latestStatus } = row.original;
                    return (
                        <span className={getPreventEventClassName(row.original)}>
                            {latestStatus === '발송중'
                                ? t('Sending in progress')
                                : formatDateByColumn(row, 'lastSendingDate') || '-'}
                        </span>
                    );
                },
            },
        ],
        [data.rows, t, checkedList],
    ) as any;

    return (
        <>
            <TableWrapper>
                <Table columns={columns} data={data} onPageChange={onPageChange} />
            </TableWrapper>
            {isOpen && <OutstandingHistory isOpen={isOpen} toggleModal={handleToggle} selectedRow={selectedRow} />}
            <ConfirmModal {...alertModalProps} />
        </>
    );
};

const TableWrapper = styled.div`
    height: 600px;
`;

export default OutstandingAmountList;
