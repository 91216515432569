import React, { useRef, useState } from 'react';
import { useAsync, useTranslation } from '@util/hooks';
import HistoryList from './Components/HistoryList';
import moment from 'moment';
import { getNotificationHistoryListApi } from '@api/so/outpatient';
import WidgetCard from '../../Components/WidgetCard';
import { WidgetProps } from '../../staticInfo';
import { UnixTimestamp } from '@util/type/util';
import CustomSearchGroup from './Components/CustomSearchGroup';
import { OptionType } from '@components/type';

export type SendingType = 'WT' | 'OD' | 'AP' | 'UP'; // 진료대기/진료차례/진료예약/미수금

export type UnpaidType = '[미수]외래현금미수' | '[미수]입원현금미수';

export interface Row {
    unpaidType: UnpaidType;
    patientId: string;
    patientName: string;
    departmentName: string;
    resultType: '성공' | '실패' | '발송중';
    appointmentDate: UnixTimestamp;
    careEndDate: UnixTimestamp;
    amount: string;
    sendingDate: UnixTimestamp;
    cardApproveNum: string;
    paymentBank: string;
}

interface Response {
    page: number;
    totalCount: number;
    totalPage: number;
    rows: Row[];
}

export interface Data extends Response {
    pageSize: number;
}

const DEFAULT_DATA: Data = {
    page: 0,
    totalCount: 0,
    totalPage: 0,
    pageSize: 0,
    rows: [],
};

export interface Filter {
    filterType: string[];
    searchType: OptionType;
    keyword: string;
    startDate: number;
    endDate: number;
    page: number;
}

const getSelectOptions = (t: (text: string) => string, centerName: string | undefined) => {
    let options: { label: string; value: SendingType | UnpaidType }[] = [];
    if (centerName === 'wonju') {
        options = [
            { label: t('Outpatient Cash Receivables'), value: '[미수]외래현금미수' },
            { label: t('Inpatient Cash Receivables'), value: '[미수]입원현금미수' },
        ];
    }

    // 현재는 미사용중인 병원
    // 추후 사용가능성 있어 남겨둠
    if (centerName === 'andong') {
        options = [
            { label: t('Wait'), value: 'WT' },
            { label: t('Order'), value: 'OD' },
            { label: t('Reservation'), value: 'AP' },
        ];
    }

    const SEARCH_OPTION = [
        { label: t('Notification Talk Sending Date'), value: 'sendingDate' },
        { label: t('Date of Receivables Occurrence'), value: 'careEndDate' },
        { label: t('Date of Receipt'), value: 'appointmentDate' },
    ];

    return { filterOption: options, searchOption: SEARCH_OPTION };
};

const NotificationHistory = ({ children, widgetInfo, ...restProps }: WidgetProps) => {
    const widgetRef = useRef(null);
    const t = useTranslation('OutpatientStorage');
    const medicalCenterName = widgetInfo.config.settings?.categoryCode; // undefined|"wonju"|"andong"
    const { filterOption, searchOption } = getSelectOptions(t, medicalCenterName);

    const option = {
        filterOption: filterOption,
        searchOption: searchOption,
    };

    const initialFilter = {
        filterType: option.filterOption.map(ele => ele.value),
        searchType: option.searchOption[0],
        keyword: '',
        startDate: moment().subtract(1, 'weeks').startOf('day').unix(),
        endDate: moment().endOf('day').unix(),
        page: 1,
    };

    const [filter, setFilter] = useState<Filter>(initialFilter);

    const { state } = useAsync({
        promise: getNotificationHistoryListApi,
        param: {
            keyword: filter.keyword || undefined,
            unpaidTypes: filter.filterType.length > 0 ? filter.filterType.toString() : undefined,
            searchType: filter.searchType.value,
            startDate: filter.startDate,
            endDate: filter.endDate,
            page: filter.page,
            pageSize: 15,
        },
        deps: [filter],
        immediate: true,
        keepState: true,
        reject: err => console.error(err),
    });

    const data = state.response ?? DEFAULT_DATA;

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            {medicalCenterName ? (
                <>
                    <CustomSearchGroup
                        options={option}
                        initialFilter={initialFilter}
                        onSubmit={(filter: Filter) => {
                            setFilter(filter);
                        }}
                        onRefreshClick={() => {
                            setFilter(initialFilter);
                        }}
                    />
                    <HistoryList
                        centerName={medicalCenterName}
                        data={data}
                        onPageChange={(page: number) => {
                            setFilter({ ...filter, page });
                        }}
                    />
                </>
            ) : (
                <div className="d-flex flex-center h-90 fw-bold fs-5">{t('Please set the medical center.')}</div>
            )}
            {children}
        </WidgetCard>
    );
};

export default NotificationHistory;
