import React, { useRef } from 'react';
import { getOutpatientUsageTime, postOutpatientUsageTimeExcel } from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import WidgetCard from '../../Components/WidgetCard';
import Chart from './Chart';
import useDepartmentOptions from '../util/useDepartmentOptions';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useRangeDateSO from '../util/useRangeDateSO';
import SearchGroupSO from '../Components/SearchGroupSO';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import moment from 'moment';
import { Trans } from 'react-i18next';
import useDateTypeOptions from '../util/useDateTypeOptions';
import { LoadingBlock } from '@components';

// 외래 사용량 분석(시간대별)
const OutpatientUsageAnalysisByTime = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const departmentValues = useDepartmentOptions();
    const dateTypeValues = useDateTypeOptions();
    const dateValues = useRangeDateSO(dateTypeValues.dateType);

    const { state, promise: getData } = useAsync({
        promise: getOutpatientUsageTime,
        immediate: true,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const { departmentName, peakTime, totalCount } = state.response ? state.response.additionalDatas : {};

    const triggerDownload = useAsyncExcel({
        promise: postOutpatientUsageTimeExcel,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            departmentCode: departmentValues.department.toString(),
            columnMetas: [
                {
                    key: 'regDay',
                    name: t('Date(day)'),
                },
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'kioskCount0800',
                    name: `${t('Usage')} (08:00) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount0830',
                    name: `${t('Usage')} (08:30) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount0900',
                    name: `${t('Usage')} (09:00) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount0930',
                    name: `${t('Usage')} (09:30) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1000',
                    name: `${t('Usage')} (10:00) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1030',
                    name: `${t('Usage')} (10:30) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1100',
                    name: `${t('Usage')} (11:00) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1130',
                    name: `${t('Usage')} (11:30) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1200',
                    name: `${t('Usage')} (12:00) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1230',
                    name: `${t('Usage')} (12:30) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1300',
                    name: `${t('Usage')} (13:00) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1330',
                    name: `${t('Usage')} (13:30) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1400',
                    name: `${t('Usage')} (14:00) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1430',
                    name: `${t('Usage')} (14:30) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1500',
                    name: `${t('Usage')} (15:00) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1530',
                    name: `${t('Usage')} (15:30) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1600',
                    name: `${t('Usage')} (16:00) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1630',
                    name: `${t('Usage')} (16:30) (${t('Cases')})`,
                },
                {
                    key: 'kioskCount1700',
                    name: `${t('Usage')} (17:00) (${t('Cases')})`,
                },
            ],
            fileName:
                'so_kioskData_' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_usage_by_time',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        if (!isValidData(data)) {
            return;
        }

        triggerDownload();
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                showDateType
                dateTypeValues={dateTypeValues}
                showDepartment
                departmentValues={departmentValues}
                dateValues={dateValues}
                getData={getData}
                showDownload={isValidData(data)}
                handleDownload={handleDownloadClick}
            />
            <LoadingBlock blocking={state.isLoading}>
                {isValidData(data) ? (
                    <>
                        <InsightBox>
                            <BoxBodyText>
                                <Trans
                                    t={t}
                                    values={{ departmentName, peakTime, totalCount }}
                                    components={[<HighlightedText />]}
                                >
                                    {data.length >= 2
                                        ? `During the inquiry period, the total kiosk usage for the selected departments was <0>{{totalCount}}</0>, with the peak usage time being <0>{{peakTime}}</0>.`
                                        : `During the inquiry period, the total kiosk usage for <0>{{departmentName}}</0> was <0>{{totalCount}}</0>, with the peak usage time being <0>{{peakTime}}</0>.`}
                                </Trans>
                            </BoxBodyText>
                        </InsightBox>
                        <Chart data={data} />
                    </>
                ) : (
                    <NoDataBlock text={t('No data')} />
                )}
            </LoadingBlock>
            {children}
        </WidgetCard>
    );
};

export default OutpatientUsageAnalysisByTime;
