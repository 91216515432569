import { createSlice } from '@reduxjs/toolkit';
import { NOTIFICATION_TYPE } from '@util/mappInfo';

// 알림 설정 정보 Key
export const NOTIFICATION_SETTING_KEY = {
    [NOTIFICATION_TYPE.USER]: 'userAlertInfo',
    [NOTIFICATION_TYPE.ROLE]: 'roleAlertInfo',
    [NOTIFICATION_TYPE.GROUP]: 'groupAlertInfoList',
};
// 알림 설정 요청 정보 Key
export const NOTIFICATION_PARAMETER_KEY = {
    [NOTIFICATION_SETTING_KEY.user]: 'userAlertSetting',
    [NOTIFICATION_SETTING_KEY.role]: 'roleAlertSetting',
    [NOTIFICATION_SETTING_KEY.group]: 'groupAlertSetting',
    alertSettingNum: 'alertSettingNum',
    groupNumList: 'loginGroups',
    propertyId: 'propertyId',
    beaconAlertInterfaceCommandList: 'interfaceBeaconList',
    sensorAlertInterfaceCommandList: 'interfaceSensorList',
};
// 센서 알림 설정의 범위 (전체, 센서 아이템)
export const SENSOR_SCOPE_KEY = {
    ALL: 'ALL',
    SINGLE: 'SINGLE',
};
const SUFFIX_TIME = '000';
const MAX_EMERGENCY_MODAL = 10;

const initialState = {
    // 긴급 알림 모달 내용 (소켓 응답 데이터) - 실제 모달 팝업 데이터
    emergencyAlertModalContents: [],
    // 긴급 알림 limit 서비스 설정 값
    originalLimit: 10,
    // 긴급 알림 서비스 설정 값
    emergencyAlertConfig: {
        useEmergencyAlert: true,
        limit: 10,
    },
    // 긴급 알림 소리 서비스 설정 값
    soundConfig: {
        useSound: false,
        sensorSoundUrl: null,
        tagSoundUrl: null,
        locationSoundUrl: null,
    },
};

// TODO: 긴급 알림 설정 추가 기획 후 변경 예정
const { actions, reducer } = createSlice({
    name: 'emergencyAlert',
    initialState,
    reducers: {
        // 긴급 알림 소켓 응답 데이터 수신 및 알림 정보 생성
        setEmergencyAlertContents: (state, action) => {
            const {
                event: { interfaceCommandType },
                target,
                unixTime: detectionTime,
                targetCategory,
                sensor: { sensorNum },
                sensingState,
                sensingValues,
                sensingUnits,
                geofence,
            } = action.payload;

            const alertKey = getAlertKey({ targetNum: target.targetNum, sensorNum });

            const emergencyAlert = {
                alertKey,
                target,
                interfaceCommandType,
                detectionTime,
                filteredSensingState: Object.entries(sensingValues).reduce((acc, [sensingType, value]) => {
                    acc[sensingType] = {
                        status: sensingState[sensingType],
                        value,
                        unit: sensingUnits[sensingType],
                    };
                    return acc;
                }, {}),
                categoryName: targetCategory.categoryName,
                geofence,
            };

            const foundIndex = state.emergencyAlertModalContents.findIndex(value => value?.alertKey === alertKey);
            if (~foundIndex) {
                state.emergencyAlertModalContents[foundIndex] = emergencyAlert;
            } else {
                // 길이가 10개 보다 많으면 하나 제거하고 추가
                if (state.emergencyAlertModalContents.length >= MAX_EMERGENCY_MODAL) {
                    state.emergencyAlertModalContents.shift();
                }
                state.emergencyAlertModalContents.push(emergencyAlert);
            }
        },
        // 긴급 알림 제거
        deleteEmergencyAlertContents: (state, action) => {
            const { alertKey, target, sensor } = action.payload;
            let deleteKey = alertKey ?? getAlertKey({ ...target, ...sensor });
            state.emergencyAlertModalContents = state.emergencyAlertModalContents.filter(
                value => value?.alertKey !== deleteKey,
            );
        },
        // 알림 서비스 설정 값
        setEmergencyAlertConfigValue: (state, action) => {
            const {
                alertSoundConfig: { rootDefaultUrl, soundOnOff, sensorStatus, tagStatus, location },
                alertPopupConfig: { popupActive, showCount, showTime },
            } = action.payload;

            // wms 서비스 설정의 긴급 알림 설정값
            const useEmergencyAlert = popupActive === 'Y'; // 긴급 알림 사용 여부
            const limit = parseInt(showCount); // 긴급 알림 노출 갯수
            const autoCloseTime = showTime + SUFFIX_TIME; // 긴급 알림 지속 시간
            state.emergencyAlertConfig = {
                ...state.emergencyAlertConfig,
                useEmergencyAlert,
                limit,
                autoClose: parseInt(autoCloseTime),
            };
            state.originalLimit = limit;

            // wms 서비스 설정의 알림 소리 설정값
            const useSound = soundOnOff === 'yes'; // 소리 사용 여부
            const sensorSoundUrl = rootDefaultUrl + sensorStatus; // 센서 알림 소리 파일
            const tagSoundUrl = rootDefaultUrl + tagStatus; // 태그 알림 소리 파일
            const locationSoundUrl = rootDefaultUrl + location; // 위치 알림 소리 파일
            state.soundConfig = {
                ...state.soundConfig,
                useSound,
                sensorSoundUrl,
                tagSoundUrl,
                locationSoundUrl,
            };
        },
        clearAlertContents: (state, action) => {
            const keepOpenedModal = action.payload?.keepOpenedModal;
            if (!keepOpenedModal) {
                state.emergencyAlertModalContents = [];
            }
        },
    },
});

export const {
    setEmergencyAlertContents,
    deleteEmergencyAlertContents,
    setEmergencyAlertConfigValue,
    clearAlertContents,
} = actions;
export default reducer;

function getAlertKey(item) {
    if (!isNaN(item?.targetNum) && !isNaN(item?.sensorNum)) {
        return item.targetNum + '_' + item.sensorNum;
    }
    return 'unknownItemKey';
}
