import { reqGet, reqPost } from '../index';

// 외래 사용량 분석(진료과)
export const getOutpatientUsageDept = param => reqGet('/v3/api/so01/report/usage/dept', param);

// 외래 사용량 분석(시간대별)
export const getOutpatientUsageTime = param => reqGet('/v3/api/so01/report/usage/hour', param);

// 외래 사용량 분석(요일별)
export const getOutpatientUsageDay = param => reqGet('/v3/api/so01/report/usage/dotw', param);

// 외래 대기시간 분석(진료과별)
export const getOutpatientWaitDept = param => reqGet('/v3/api/so01/report/user/dept', param);

// 외래 대기시간 분석(시간대별 평균)
export const getOutpatientWaitTime = param => reqGet('/v3/api/so01/report/user/hour', param);

// 외래 사용량 분석(진료과) 엑셀 다운로드
export const postOutpatientUsageDeptExcel = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/so01/report/usageDown/dept',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

// 외래 사용량 분석(시간대별) 엑셀 다운로드
export const postOutpatientUsageTimeExcel = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/so01/report/usageDown/hour',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

// 외래 사용량 분석(요일별) 엑셀 다운로드
export const postOutpatientUsageDayExcel = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/so01/report/usageDown/dotw',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

// 외래 대기시간 분석(진료과별) 엑셀 다운로드
export const postOutpatientWaitDeptExcel = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/so01/report/userDown/dept',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

// 외래 환자 대기시간 조회 엑셀 다운로드
export const postOutpatientWaitTimeListExcel = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/so01/report/userDown/waits/dept',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

// 외래 대기시간 분석(시간대별 평균) 엑셀 다운로드
export const postOutpatientWaitTimeExcel = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/so01/report/userDown/hour',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

// 미수금 알림톡 리스트 조회
export const getOutstandingAmountList = param => {
    return reqGet(`/v3/api/so01/unpaid-amount`, param);
};

// 미수금 알림톡 상세 조회
export const getOutstandingAmountDetailApi = ({ ...param }) => {
    return reqGet(`/v3/api/so01/unpaid-amount/detail`, param);
};

// 미수금 알림톡 알림톡 발송
export const postOutstandingAmountNotification = param => reqPost('/v3/api/so01/unpaid-amount/alarm', param);

// 알림톡 발송 내역 조회
export const getNotificationHistoryListApi = param => {
    return reqGet('/v3/api/so01/alarm-talk', param);
};

// 알림톡 발송 내역 엑셀 다운로드
export const postNotificationHistoryExcel = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/so01/alarm-talk/export',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

// 알림톡 발송 통계 조회
export const getNotificationStatistics = param => reqGet('/v3/api/so01/alarm-talk/statistics', param);

// 알림톡 발송 통계 엑셀 다운로드
export const postNotificationStatisticsExcel = ({ columnMetas, zipFileName, fileName, ...restParam }) =>
    reqPost(
        '/v3/api/so01/alarm-talk/statistics/export',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );
