import { WidgetProps } from '../../staticInfo';
import ContextProvider from '@components/ContextProvider';
import deviceMappingManagementSlice, {
    DeviceMappingManagementDispatchContext,
    DeviceMappingManagementStateContext,
    useDeviceMappingManagementStateContext,
} from './slice';
import WidgetCard from '../../Components/WidgetCard';
import React, { useState } from 'react';
import PatientsDeviceList from './Components/DeviceList/PatientsDeviceList';
import DeviceManagementFilterGroup from './Components/Filter/DeviceMappingManagementFilterGroup';
import { useAppSelector, useAsync } from '@hooks';
import { getTargetDeviceListApi } from '@api/sh/deviceManagement';
import { MODEL_CODE } from '@util/staticData/sensorMeta';
import useFilterSetting from './hooks/useFilterSetting';
import { CATEGORY_CODE } from '@util/mappInfo';

const DeviceMappingManagementContainer = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const {
        monitoringFilter: { wardList: monitoringWardList, roomList, isMapping, isHospitalized },
    } = useDeviceMappingManagementStateContext();
    const [targetIdOrName, setTargetIdOrName] = useState('');
    const { handleResetFilter } = useFilterSetting();
    const wardList = useAppSelector(state =>
        Object.entries(state.DepartmentsInfo.wardMapInfo).map(([wardNum, wardName]) => ({ wardNum, wardName })),
    );

    const { promise: getTargetDeviceList, state: targetDeviceList } = useAsync({
        promise: getTargetDeviceListApi,
        fixedParam: {
            targetIdOrName: targetIdOrName.trim(),
            categoryCodes: CATEGORY_CODE.PATIENT,
            targetStatus: isHospitalized,
            targetMapped: isMapping,
            modelCodeList: [MODEL_CODE.MEZOO_SMARTPATCH],
            targetPropertiesSearch: {
                categoryCode: CATEGORY_CODE.PATIENT,
                propertySearchs:
                    monitoringWardList.length !== 0
                        ? monitoringWardList.map((ward: string) => ({
                              propertyId: 'ward',
                              values: ward,
                          }))
                        : wardList.map(({ wardNum }) => ({
                              propertyId: 'ward',
                              values: wardNum,
                          })),
            },
            roomList,
            page: 1,
            pageSize: 15,
        },
        immediate: true,
        keepState: true,
        deps: [monitoringWardList.length, roomList.length, isHospitalized, isMapping],
    });

    const handleReset = () => {
        handleResetFilter();
        setTargetIdOrName('');

        getTargetDeviceList({ targetIdOrName: '' });
    };

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            {...restProps}
            searchFilter={
                <DeviceManagementFilterGroup
                    targetIdOrName={targetIdOrName}
                    handleTargetIdOrName={setTargetIdOrName}
                    handleReset={handleReset}
                    handleSearch={getTargetDeviceList}
                />
            }
        >
            <PatientsDeviceList getTargetDeviceList={getTargetDeviceList} targetDeviceList={targetDeviceList} />
            {children}
        </WidgetCard>
    );
};

const DeviceMappingManagement = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    return (
        <ContextProvider
            StateContext={DeviceMappingManagementStateContext}
            DispatchContext={DeviceMappingManagementDispatchContext}
            slice={deviceMappingManagementSlice}
        >
            <DeviceMappingManagementContainer widgetInfo={widgetInfo} {...restProps}>
                {children}
            </DeviceMappingManagementContainer>
        </ContextProvider>
    );
};

export default DeviceMappingManagement;
