import { useAsync } from '@util/hooks';
import { useState } from 'react';
import { Filter } from '..';
import { getOutstandingAmountList } from '@api/so/outpatient';
import { UnixTimestamp } from '@util/type/util';
import { useOutstandingAmountStateContext } from '../slice';

export interface Row {
    patientId: string;
    patientName: string;
    phoneNum: string;
    unpaidAmountNum: number;
    unpaidType: string;
    departmentCode: string;
    totalAmount: string;
    careEndDate: UnixTimestamp;
    appointmentDate: UnixTimestamp;
    latestStatus: '성공' | '실패' | '발송중';
    lastSendingDate: UnixTimestamp;
    amount: string;
    historyCount: number;
}

interface Response {
    page: number;
    totalCount: number;
    totalPage: number;
    rows: Row[];
}

interface Param {
    keyword: string;
    sortOption: string;
    searchType: string;
    startDate: UnixTimestamp;
    endDate: UnixTimestamp;
    hideTodaySendUnpaid: 0 | 1;
    page: number;
    pageSize: number;
}

export interface Data extends Response {
    pageSize: number;
}

export const useAmountList = (filter: Filter) => {
    const [data, setData] = useState<Data>();
    const { hide } = useOutstandingAmountStateContext();

    const { promise: getList } = useAsync<Param, Response>({
        promise: getOutstandingAmountList,
        immediate: true,
        param: {
            keyword: filter.keyword,
            sortOption: filter.sortOption?.value,
            searchType: filter.searchType?.value,
            startDate: filter?.startDate,
            endDate: filter?.endDate,
            page: filter.page,
            hideTodaySendUnpaid: hide ? 1 : 0,
            pageSize: 15,
        },
        deps: [filter, hide],
        resolve: (response, request) => {
            const { pageSize } = request;
            setData({ ...response, pageSize });
        },
        reject: err => console.error(err),
    });

    return { data, setData, getList };
};
