import { fontColorByTheme } from './index';
import { TIME_LABELS_DATA } from '../../constants';
import moment from 'moment';

// 차트 호버시 백그라운드를 입히는 플러그인
export const hoverBackgroundPlugin = {
    id: 'so/hoverBackgroundPlugin',
    beforeDraw: chart => {
        const {
            ctx,
            tooltip,
            chartArea: { top, bottom, left, right, width, height },
            scales: { x, y },
        } = chart;

        if (tooltip._active[0]) {
            const index = tooltip._active[0].index;

            // 그리드 라인 항목의 x1, x2 값을 이용해 정확한 영역을 계산
            const startX = x._gridLineItems[index].x1; // 해당 x값 시작
            const endX = x._gridLineItems[index + 1] ? x._gridLineItems[index + 1].x1 : startX + 10; // 끝 x값 (다음 그리드 라인까지)

            // 호버된 영역에 대한 배경색을 그린다.
            ctx.fillStyle = 'rgba(255, 239, 176, 0.49)';
            ctx.fillRect(startX, top, endX - startX, height); // x1 ~ x2 사이에 배경색 채우기
        }
    },
};

// 커스텀 legend를 띄우는 Plugin
// custom-legend-container id를 가진 div요소가 반드시 필요
export const customLegendPlugin = {
    id: 'so/customLegendPlugin',
    afterUpdate: chart => {
        const legendContainer = document.getElementById('custom-legend-container');
        if (!legendContainer) return;

        // Clear previous legend
        while (legendContainer.firstChild) {
            legendContainer.firstChild.remove();
        }

        // Create new legend items
        chart.data.datasets.forEach((dataset, index) => {
            const legendItem = document.createElement('div');
            legendItem.style.display = 'flex';
            legendItem.style.alignItems = 'center';
            legendItem.style.cursor = 'pointer';

            // Legend color box
            const colorBox = document.createElement('span');
            colorBox.style.backgroundColor = dataset.hidden
                ? 'rgba(0, 0, 0, 0.2)' // 클릭된 데이터셋에 투명도 적용
                : dataset.backgroundColor;
            colorBox.style.width = '12px';
            colorBox.style.height = '12px';
            colorBox.style.display = 'inline-block';
            colorBox.style.marginRight = '8px';

            if (dataset.type === 'line') {
                colorBox.style.borderRadius = '15px';
            }

            legendItem.appendChild(colorBox);

            // Legend label
            const label = document.createElement('span');
            label.textContent = dataset.label;
            label.style.textDecoration = dataset.hidden ? 'line-through' : 'none'; // 취소선 적용
            legendItem.appendChild(label);

            // Add event listener to toggle dataset visibility
            legendItem.addEventListener('click', () => {
                const meta = chart.getDatasetMeta(index);
                meta.hidden = !meta.hidden;

                // Update dataset styles
                dataset.hidden = meta.hidden;
                label.style.textDecoration = meta.hidden ? 'line-through' : 'none'; // 취소선 토글
                colorBox.style.backgroundColor = meta.hidden
                    ? 'rgba(0, 0, 0, 0.2)' // 투명도 적용
                    : dataset.backgroundColor; // 원래 색상 복원

                chart.update();
            });

            legendContainer.appendChild(legendItem);
        });
    },
};

// 진료과별 사용량분석 에서만 사용하는 Plugin
export const topLabels = {
    id: 'so/topLabels',
    afterDatasetDraw: (chart, args, pluginOptions) => {
        const { ctx } = chart;

        chart.data.datasets[2].data.forEach((datapoint, index) => {
            ctx.font = 'bold 14px';
            ctx.fillStyle = fontColorByTheme(document.documentElement.getAttribute('color-theme'));
            ctx.textAlign = 'center';
            ctx.fillText(datapoint, chart.getDatasetMeta(2).data[index].x, chart.getDatasetMeta(2).data[index].y - 10);
        });

        chart.data.datasets[0].data.forEach((datapoint, index) => {
            const datasetArray = [];

            chart.data.datasets.forEach(dataset => {
                if (dataset.stack === 'patient') {
                    datasetArray.push(dataset.data[index]);
                }
            });

            let sum = datasetArray.reduce((acc, curr) => acc + curr, 0);

            ctx.font = 'bold 14px';
            ctx.fillStyle = fontColorByTheme(document.documentElement.getAttribute('color-theme'));
            ctx.textAlign = 'center';
            ctx.fillText(sum, chart.getDatasetMeta(1).data[index].x, chart.getDatasetMeta(1).data[index].y - 10);
        });
    },
};

// 사용량건수 시간대별 표시에만 사용하는 Plugin(labels가 커스텀되어있어 개별로 사용)
export const timeFloatingLabels = t => ({
    id: 'so/timeFloatingLabels',
    afterDatasetsDraw(chart) {
        const { ctx, chartArea, scales } = chart;

        if (chart.data.datasets.length === 1) {
            return;
        }
        ctx.save();
        ctx.fillStyle = fontColorByTheme(document.documentElement.getAttribute('color-theme'));
        ctx.textAlign = 'center';

        // X축 값에 맞는 위치 계산
        TIME_LABELS_DATA.forEach((label, index) => {
            const xPos = scales.x.getPixelForValue(moment(label.x, 'HH:mm').toDate()); // 실제 X축 15분 단위에 맞는 위치'
            const yPos = chartArea.top - 10; // 라벨 위치 (상단)

            // 해당 X축 틱의 데이터 합계 계산
            const total = chart.data.datasets.reduce((sum, dataset) => {
                return !dataset?.hidden ? sum + (dataset.data[index].y || 0) : sum;
            }, 0);

            // 합계 라벨 그리기
            ctx.fillText(`${t('Total')} ${total}${t('Cases')}`, xPos, yPos);
        });

        ctx.restore();
    },
});

// 사용량건수 요일별 표시에만 사용하는 Plugin
export const dayFloatingLabels = t => ({
    id: 'so/dayFloatingLabels',
    afterDatasetsDraw(chart) {
        const { ctx, chartArea, scales, data: chartData } = chart;

        if (chart.data.datasets.length === 1) {
            return;
        }

        // X축 라벨과 데이터셋 값 가져오기
        const xLabels = chartData.labels;
        const datasets = chartData.datasets;

        // Floating label 속성
        ctx.save();
        ctx.fillStyle = fontColorByTheme(document.documentElement.getAttribute('color-theme'));
        ctx.textAlign = 'center';

        // X축 위치에 대해 합산된 값을 계산하고 라벨을 그립니다.
        xLabels.forEach((label, index) => {
            const xPos = scales.x.getPixelForTick(index); // X축 위치 가져오기
            const yPos = chartArea.top - 10; // 차트 상단 위로 약간 띄움

            // 해당 X축 위치에 포함된 데이터의 합산 값 계산
            let totalValue = 0;

            // 각 데이터셋에서 해당 X축에 해당하는 데이터 값을 합산
            datasets.forEach(dataset => {
                if (dataset?.hidden) return;
                // 데이터셋에서 해당 X축에 해당하는 데이터 값 가져오기
                totalValue += dataset.data[index] || 0;
            });

            // 합산된 값을 라벨로 그리기
            ctx.fillText(`${t('Total')} ${totalValue}${t('Cases')}`, xPos, yPos);
        });

        ctx.restore();
    },
});
