import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import cx from 'classnames';
import { FilterList, InputGroup } from '../../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../../MainPages/Components/FilterSearchGroup';
import FilterGroup from '../../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import { useTranslation } from '@hooks';
import SearchableSelect from '@components/Select/SearchableSelect';
import {
    setMonitoringFilter,
    useDeviceMappingManagementDispatchContext,
    useDeviceMappingManagementStateContext,
} from '../../slice';
import { Button, TextInput } from '@components';
import Select from '@components/Select';
import { SingleValue } from 'react-select';
import useFilterSetting from '../../hooks/useFilterSetting';

interface Props {
    targetIdOrName: string;
    handleTargetIdOrName: Dispatch<SetStateAction<string>>;
    handleReset: () => void;
    handleSearch: () => void;
}

const DeviceMappingManagementFilterGroup = ({
    targetIdOrName,
    handleTargetIdOrName,
    handleReset,
    handleSearch,
}: Props) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const dispatch = useDeviceMappingManagementDispatchContext();
    const { monitoringFilter } = useDeviceMappingManagementStateContext();
    const { wardList, roomList, mappingOptions, hospitalizedOptions } = useFilterSetting();

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSearch();
    };

    return (
        <FilterSearchGroup className={cx('absolute-filter', 'w-100')}>
            <FilterGroup className={''} label={t('Filter', 'Filter')}>
                <FilterList className={''}>
                    <SearchableSelect
                        data={wardList}
                        labelKey={'wardName'}
                        valueKey={'wardNum'}
                        selected={monitoringFilter.wardList}
                        onChange={selected => {
                            dispatch(
                                setMonitoringFilter({
                                    wardList: (selected ?? []).map((ward: { wardNum: string }) => ward.wardNum),
                                }),
                            );
                        }}
                    />
                    <SearchableSelect
                        data={roomList}
                        labelKey={'roomName'}
                        valueKey={'roomNum'}
                        selected={monitoringFilter.roomList}
                        onChange={selected => {
                            dispatch(
                                setMonitoringFilter({
                                    roomList: (selected ?? []).map((room: { roomNum: string }) => room.roomNum),
                                }),
                            );
                        }}
                    />
                    <Select
                        customContainerStyles={{ width: '100px' }}
                        options={hospitalizedOptions}
                        value={hospitalizedOptions.find(option => option.value === monitoringFilter.isHospitalized)}
                        onChange={(selected: SingleValue<any>) => {
                            dispatch(setMonitoringFilter({ isHospitalized: selected?.value ?? 'IN' }));
                        }}
                    />
                    <Select
                        customContainerStyles={{ width: '200px' }}
                        options={mappingOptions}
                        value={mappingOptions.find(option => option.value === monitoringFilter.isMapping)}
                        onChange={(selected: SingleValue<any>) => {
                            dispatch(setMonitoringFilter({ isMapping: selected?.value ?? 'N' }));
                        }}
                    />
                    <form className={'flx-row gap-2'} onSubmit={handleSubmit}>
                        <InputGroup>
                            <TextInput
                                inputGroupClassName={'w-100'}
                                type="text"
                                name="targetIdOrName"
                                value={targetIdOrName}
                                handleChange={e => handleTargetIdOrName(e.target.value)}
                                placeholder={t("Enter the patient's name or patient registration number.")}
                            />
                        </InputGroup>
                        <Button type="submit" className="btn-secondary">
                            {t('Lookup', 'Search')}
                        </Button>
                        <Button className="btn-line btn-icon-only" iconName="refresh" onClick={handleReset} />
                    </form>
                </FilterList>
            </FilterGroup>
        </FilterSearchGroup>
    );
};

export default DeviceMappingManagementFilterGroup;
