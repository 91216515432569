import React, { useState } from 'react';
import { Button, Checkbox, DatePicker, Select, TextInput } from '@components';
import { useTranslation } from '@util/hooks';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import FilterGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import SearchGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import SendContainer from './SendContainer';
import { setHideToggle, useOutstandingAmountDispatchContext, useOutstandingAmountStateContext } from '../slice';

const CustomSearchGroup = ({ initialFilter, options, onSubmit, onRefreshClick, onRefreshList }) => {
    const t = useTranslation('OutpatientStorage');
    const { checkedList } = useOutstandingAmountStateContext();
    const dispatch = useOutstandingAmountDispatchContext();
    const [filterDraft, setFilterDraft] = useState(initialFilter);

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(filterDraft);
    };

    const handleRefreshClick = () => {
        setFilterDraft(initialFilter);
        onRefreshClick();
    };

    return (
        <FilterSearchGroup className="p-0">
            <FilterGroup label={t('Order', 'Filter')} labelIconName={''}>
                <Select
                    value={filterDraft.sortOption}
                    options={options.orderOptions}
                    onChange={selected => {
                        setFilterDraft({ ...filterDraft, sortOption: selected });
                        onSubmit({ ...filterDraft, sortOption: selected });
                    }}
                />
                <Checkbox
                    name={'오늘 알림톡 발송 미수금 숨김'}
                    handleChecked={() => {
                        dispatch(setHideToggle());
                    }}
                />
            </FilterGroup>
            <SearchGroup label={t('Search', 'Search')} labelIconName={''}>
                <div className={'w-100 d-flex justify-content-between gap-2'}>
                    <div className={'d-flex gap-2'}>
                        <Select
                            value={options.searchOptions.find(ele => ele.value === filterDraft.searchType.value)}
                            options={options.searchOptions}
                            onChange={selected => {
                                setFilterDraft({ ...filterDraft, searchType: selected });
                            }}
                        />
                        <DatePicker
                            value={filterDraft.startDate}
                            handleChange={selected => {
                                setFilterDraft({ ...filterDraft, startDate: selected });
                                onSubmit({ ...filterDraft, startDate: selected });
                            }}
                            withoutTime
                            maxDate={filterDraft.endDate * 1000}
                            disabledKeyboardNavigation
                        />
                        <span>~</span>
                        <DatePicker
                            value={filterDraft.endDate}
                            handleChange={selected => {
                                setFilterDraft({ ...filterDraft, endDate: selected });
                                onSubmit({ ...filterDraft, endDate: selected });
                            }}
                            withoutTime
                            minDate={filterDraft.startDate * 1000}
                            disabledKeyboardNavigation
                        />
                        <form onSubmit={handleSubmit}>
                            <div className="d-flex">
                                <TextInput
                                    value={filterDraft.keyword}
                                    placeholder={t('Enter the patients name or patients ID.')}
                                    handleChange={e => setFilterDraft({ ...filterDraft, keyword: e.target.value })}
                                />
                                <Button type="submit" className="btn-icon-only btn-secondary" iconName={'search'} />
                                <Button
                                    className="btn-line btn-icon-only ml-2"
                                    iconName="refresh"
                                    onClick={handleRefreshClick}
                                />
                            </div>
                        </form>
                    </div>
                    <div>
                        <SendContainer
                            checkedList={checkedList}
                            disabled={checkedList.length === 0}
                            refresh={onRefreshList}
                        />
                    </div>
                </div>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default CustomSearchGroup;
