import { useEffect, useMemo } from 'react';
import { YN } from '@util/type/util';
import { useAppSelector, useAsync, useTranslation } from '@hooks';
import { getRoomListApi } from '@api/sh/deviceManagement';
import {
    setMonitoringFilter,
    useDeviceMappingManagementDispatchContext,
    useDeviceMappingManagementStateContext,
} from '../slice';
import { OptionType, PatientStatus } from '../type';
import { CATEGORY_CODE } from '@util/mappInfo';

export const getInitWardList = (groupNums?: number[]) => {
    return (groupNums || []).map(num => String(num));
};

const useFilterSetting = () => {
    const t = useTranslation('DeviceMappingManagement');
    const dispatch = useDeviceMappingManagementDispatchContext();
    const { monitoringFilter } = useDeviceMappingManagementStateContext();
    const statusOption = useAppSelector(
        state =>
            state.CategoryInfo.categoryPropertiesList.find(
                item => item.categoryCode === CATEGORY_CODE.PATIENT && item.propertyId === 'status',
            )!.inputValues as PatientStatus[],
    );
    const userInfo: { groupNums?: number[] } = useAppSelector(state => state.UserInfo.userInfo);
    const wardList = useAppSelector(state =>
        Object.entries(state.DepartmentsInfo.wardMapInfo).map(([wardNum, wardName]) => ({ wardNum, wardName })),
    );

    const {
        state: { response: roomListInfo },
    } = useAsync({
        promise: getRoomListApi,
        fixedParam: { wardList: monitoringFilter.wardList ?? [] },
        deps: [monitoringFilter.wardList],
        immediate: true,
    });

    const roomList = useMemo(() => {
        return (roomListInfo?.rows ?? []).reduce(
            (acc: { roomNum: string; roomName: string }[], roomInfo: { ward: string; room: string }) => {
                if (
                    !monitoringFilter.wardList ||
                    !monitoringFilter.wardList.length ||
                    monitoringFilter.wardList?.includes(roomInfo.ward)
                ) {
                    acc.push({
                        roomNum: roomInfo.room,
                        roomName: roomInfo.room,
                    });
                }
                return acc;
            },
            [],
        );
    }, [roomListInfo, monitoringFilter.wardList]);

    useEffect(() => {
        if (userInfo.groupNums && userInfo.groupNums.length) {
            dispatch(setMonitoringFilter({ wardList: getInitWardList(userInfo.groupNums) }));
        }
    }, [userInfo]);

    useEffect(() => {
        if (roomListInfo?.rows) {
            dispatch(
                setMonitoringFilter({
                    roomList: roomList.map(({ roomNum }: { roomNum: string }) => roomNum),
                }),
            );
        }
    }, [roomList]);

    // 입원 퇴원 Select Option
    const isHospitalizedOptions = useMemo<OptionType<string>[]>(() => {
        const option = statusOption.map(({ name, value }) => ({
            label: name,
            value,
        }));

        return [{ value: 'ALL', label: t('All Patients') }, ...option];
    }, [t]);

    // 매핑 여부 Select Option
    const isMappingOptions = useMemo<OptionType<YN>[]>(() => {
        return [
            { value: 'Y', label: t('View device mapped patients') },
            { value: 'N', label: t('View all patients') },
        ];
    }, [t]);

    const handleResetFilter = () => {
        dispatch(
            setMonitoringFilter({
                wardList: getInitWardList(userInfo.groupNums),
                isMapping: 'N',
                isHospitalized: 'IN',
            }),
        );
    };

    return {
        mappingOptions: isMappingOptions,
        hospitalizedOptions: isHospitalizedOptions,
        roomList,
        wardList,
        handleResetFilter,
    };
};

export default useFilterSetting;
