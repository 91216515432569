import React from 'react';
import { Button, Modal, Table } from '@components';
import { useAsync, useColumns, useTranslation } from '@hooks';
import { ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { getNotificationHistoryListApi, getOutstandingAmountDetailApi } from '@api/so/outpatient';
import { formatDateByColumn } from '../../../util/format';
import { NullableString } from '@util/type/util';
import { Row } from '../hooks/useAmountList';

interface Props {
    isOpen: boolean;
    toggleModal: () => void;
    selectedRow: Row | null;
}

const OutstandingHistory = ({ isOpen, toggleModal, selectedRow }: Props) => {
    const t = useTranslation('OutpatientStorage');
    const { unpaidAmountNum } = selectedRow || { unpaidAmountNum: null };
    const columns = useColumns([
        {
            Header: t('Result'),
            accessor: 'resultType',
            Cell: ({ value }: { value: NullableString }) => {
                return value || '-';
            },
        },
        {
            Header: t('Sent date'),
            accessor: 'sendingDate',
            Cell: ({ row }: { row: Row }) => {
                return <span>{formatDateByColumn(row, 'sendingDate') || '-'}</span>;
            },
        },
    ]);

    const { state: notificationHistoryList, promise: getNotificationHistoryList } = useAsync({
        promise: getNotificationHistoryListApi,
        fixedParam: { unpaidAmountNum: unpaidAmountNum, pageSize: 5 },
        immediate: true,
        keepState: true,
    });

    const { state: outstandingAmountDetail, promise: getOutstandingAmountDetail } = useAsync({
        promise: getOutstandingAmountDetailApi,
        fixedParam: { unpaidAmountNum: unpaidAmountNum },
        immediate: true,
        keepState: true,
        reject: err => console.error(err),
    });

    const { totalCount, successCount, failureCount } = outstandingAmountDetail?.response || {
        totalCount: 0,
        successCount: 0,
        failureCount: 0,
    };

    const data = notificationHistoryList?.response ?? { rows: [] };

    return (
        <Modal
            initModal={isOpen}
            toggleModal={toggleModal}
            headerTitle={t('Notification Send History')}
            removeCancel={true}
            removeModalClose={false}
            modalFooter={<></>}
        >
            <ModalBody>
                <div>
                    {t('Total Sending History')}: {totalCount}({t('Success')}: {successCount} {t('Failure')}:{' '}
                    {failureCount})
                </div>
                <TableWrapper>
                    <Table
                        columns={columns}
                        data={{ ...data, pageSize: 5 }}
                        onPageChange={page => {
                            getNotificationHistoryList({ page });
                        }}
                    />
                </TableWrapper>
            </ModalBody>
            <ModalFooter className={'d-flex justify-content-center align-items-center'}>
                <Button className={'btn-secondary'} onClick={toggleModal}>
                    {t('Confirm')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default OutstandingHistory;

const TableWrapper = styled.div`
    height: 240px;
`;
