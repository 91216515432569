import { createContext, Dispatch, useContext } from 'react';
import { ActionType } from '@components/ContextProvider';
import { createSlice } from '@reduxjs/toolkit';

interface InitState {
    checkedList: number[];
    hide: boolean;
}

const initialState: InitState = {
    checkedList: [],
    hide: false,
};

export const OutstandingAmountStateContext = createContext(initialState);
export const OutstandingAmountDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const useOutstandingAmountStateContext = () => useContext(OutstandingAmountStateContext);
export const useOutstandingAmountDispatchContext = () => useContext(OutstandingAmountDispatchContext);

const outstandingAmountNotificationSlice = createSlice({
    name: 'so/outstandingAmountNotification',
    initialState,
    reducers: {
        setCheckedList: (state, action) => {
            state.checkedList = action.payload;
        },
        setHideToggle: state => {
            state.hide = !state.hide;
        },
    },
});

export const { setCheckedList, setHideToggle } = outstandingAmountNotificationSlice.actions;

export default outstandingAmountNotificationSlice;
