import moment from 'moment';

export const fontColorByTheme = theme => {
    return theme === 'pnt-dark' ? 'white' : 'black';
};

export const createTooltipBase = (context, callback) => {
    const tooltipModel = context.tooltip;
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // 툴팁이 처음 열릴 때 DOM 요소 생성
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<div class="custom-tooltip"></div>';
        document.body.appendChild(tooltipEl);
    }

    // 툴팁 깜빡임 방지
    tooltipEl.style.cssText = `
      background-color: rgb(75,75,75);
      border-radius: 3px;
      color: #fff;
      position: absolute;
      z-index: 9999;
      padding: 1rem;
      pointer-events: none;
      touch-action: none;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    `;

    // 툴팁이 감춰진 상태일 때 처리
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
    }

    const dataPoints = tooltipModel.dataPoints;
    if (dataPoints[0]) {
        tooltipEl.innerHTML = `<div>${dataPoints[0].label}</div>`;
    }
    callback(dataPoints, tooltipEl);

    // 툴팁 위치 설정
    const position = context.chart.canvas.getBoundingClientRect();
    const tooltipWidth = tooltipEl.offsetWidth;
    const tooltipHeight = tooltipEl.offsetHeight;

    // 기본 위치 계산
    let tooltipX = position.left + tooltipModel.caretX + 50;
    let tooltipY = position.top + tooltipModel.caretY - 250;

    // 오른쪽 경계 체크 (캔버스 기준)
    if (tooltipX + tooltipWidth > position.right) {
        tooltipX = tooltipX - tooltipWidth - 100; // 원래 오프셋 유지
    }

    // 왼쪽 경계 체크
    if (tooltipX < position.left) {
        tooltipX = position.left + 10; // 약간의 여백 추가
    }

    // 상단 경계 체크
    if (tooltipY < position.top) {
        tooltipY = position.top + 10;
    }

    // 하단 경계 체크
    if (tooltipY + tooltipHeight > position.bottom) {
        tooltipY = position.bottom - tooltipHeight - 10;
    }

    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = tooltipX + 'px';
    tooltipEl.style.top = tooltipY + 'px';
};

/**
 * 입력된 날짜가 오늘인지 확인하는 함수
 * @param {number} date - 비교할 날짜 (YYYY-MM-DD 형식)
 * @returns {boolean} - true이면 오늘, false이면 오늘이 아님
 */
export function isToday(date) {
    const inputDate = moment.unix(date);
    const today = moment();
    return inputDate.isSame(today, 'day');
}
